// File: src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProfilePage = ({ authToken, setAuthToken }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to load profile. Please log in again.');
        setAuthToken(null); // Clear token on failure
        localStorage.removeItem('authToken');
        navigate('/login'); // Redirect to login on error
      } finally {
        setLoading(false);
      }
    };

    if (authToken) {
      fetchProfile();
    }
  }, [authToken, setAuthToken, navigate]);

  const handleLogout = () => {
    setAuthToken(null);
    setUser({});
    localStorage.removeItem('authToken');
    navigate('/login'); // Redirect to login on logout
  };

  return (
    <div className="p-6 max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">Profile</h1>

      {/* Error Message if profile fetch fails */}
      {error && (
        <div className="bg-red-100 text-red-800 p-4 rounded mb-4">
          {error}
        </div>
      )}

      {/* Show loader while fetching profile */}
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="spinner-border text-blue-500 w-8 h-8 border-4 rounded-full animate-spin"></div>
        </div>
      ) : user?.username ? (
        <div>
          <p className="text-gray-700">
            <strong>Username:</strong> {user.username}
          </p>
          <p className="text-gray-700">
            <strong>Email:</strong> {user.email}
          </p>

          <button
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      ) : (
        <p className="text-red-600">Profile not available. Please log in.</p>
      )}
    </div>
  );
};

export default ProfilePage;
