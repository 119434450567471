// File: src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import Navbar
import Footer from './components/Footer'; // Import Footer
import LandingPage from './pages/LandingPage'; // Import LandingPage
import SearchPage from './pages/SearchPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage'; // Import ForgotPasswordPage
import ResetPasswordPage from './pages/ResetPasswordPage'; // Import ResetPasswordPage
import ProfilePage from './pages/ProfilePage';
import PriceAlertPage from './pages/PriceAlertPage';
import NotificationsPage from './pages/NotificationsPage'; // Import NotificationsPage
import VendorRegister from './pages/VendorRegister';
import VendorLogin from './pages/VendorLogin';
import VendorDashboard from './pages/VendorDashboard';

const App = () => {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('authToken') || null);
  const [vendorAuthToken, setVendorAuthToken] = useState(() => localStorage.getItem('vendorAuthToken') || null);

  const manageToken = (token, setTokenState, storageKey) => {
    try {
      if (token) {
        localStorage.setItem(storageKey, token);
      } else {
        localStorage.removeItem(storageKey);
      }
      setTokenState(token);
    } catch (error) {
      console.error(`Error managing ${storageKey} in localStorage:`, error);
    }
  };

  useEffect(() => {
    manageToken(authToken, setAuthToken, 'authToken');
  }, [authToken]);

  useEffect(() => {
    manageToken(vendorAuthToken, setVendorAuthToken, 'vendorAuthToken');
  }, [vendorAuthToken]);

  return (
    <Router>
      {/* Flexbox wrapper to ensure footer sticks to the bottom */}
      <div className="flex flex-col min-h-screen">
        {/* Include Navbar */}
        <Navbar />

        {/* Main Content */}
        <main className="flex-grow">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LandingPage />} /> {/* Landing Page Route */}
            <Route path="/search" element={<SearchPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/login"
              element={authToken ? <Navigate to="/profile" /> : <LoginPage setAuthToken={setAuthToken} />}
            />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} /> {/* Forgot Password Route */}
            <Route path="/reset-password" element={<ResetPasswordPage />} /> {/* Reset Password Route */}

            {/* Vendor Routes */}
            <Route path="/vendor/register" element={<VendorRegister />} />
            <Route
              path="/vendor/login"
              element={
                vendorAuthToken ? (
                  <Navigate to="/vendor/dashboard" />
                ) : (
                  <VendorLogin setVendorAuthToken={setVendorAuthToken} />
                )
              }
            />
            <Route
              path="/vendor/dashboard"
              element={
                vendorAuthToken ? (
                  <VendorDashboard
                    vendorAuthToken={vendorAuthToken}
                    setVendorAuthToken={setVendorAuthToken}
                  />
                ) : (
                  <Navigate to="/vendor/login" />
                )
              }
            />

            {/* Protected Routes for User */}
            <Route
              path="/profile"
              element={
                authToken ? (
                  <ProfilePage authToken={authToken} setAuthToken={setAuthToken} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/alerts"
              element={
                authToken ? (
                  <PriceAlertPage authToken={authToken} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* Notifications Route */}
            <Route
              path="/notifications"
              element={
                authToken ? (
                  <NotificationsPage authToken={authToken} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* Root Redirect */}
            <Route path="/dashboard" element={<Navigate to={authToken ? '/profile' : '/login'} />} />
          </Routes>
        </main>

        {/* Include Footer */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
