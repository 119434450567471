import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PriceAlertPage = ({ authToken }) => {
  const [alerts, setAlerts] = useState([]);
  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [targetPrice, setTargetPrice] = useState('');
  const [notificationMethod, setNotificationMethod] = useState('email');
  const [loading, setLoading] = useState(true); // Loading state for fetching alerts
  const [error, setError] = useState('');

  // Fetch user alerts on component mount
  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/alerts/user-alerts`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setAlerts(response.data);
      } catch (err) {
        setError('Failed to load alerts. Please try again.');
        console.error('Error fetching alerts:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchAlerts();
  }, [authToken]);

  // Create a new alert
  const handleCreateAlert = async () => {
    setError('');
    try {
      const newAlert = {
        productId,
        productName,
        targetPrice,
        notificationMethod,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/alerts/create`, newAlert, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setAlerts([...alerts, response.data.alert]);
      setProductName('');
      setProductId('');
      setTargetPrice('');
    } catch (err) {
      setError('Failed to create alert. Please try again.');
      console.error('Error creating alert:', err);
    }
  };

  // Delete an alert by ID
  const handleDeleteAlert = async (id) => {
    setError('');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/alerts/delete/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setAlerts(alerts.filter(alert => alert._id !== id));
    } catch (err) {
      setError('Failed to delete alert. Please try again.');
      console.error('Error deleting alert:', err);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Manage Price Alerts</h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Loading Indicator */}
      {loading ? (
        <div className="flex justify-center items-center mt-6">
          <div className="loader border-t-4 border-blue-500"></div>
        </div>
      ) : (
        <>
          {/* Alert Creation Form */}
          <div className="bg-white shadow rounded-lg p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">Create New Alert</h2>
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Product Name"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Product ID"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
              />
              <input
                type="number"
                placeholder="Target Price"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                value={targetPrice}
                onChange={(e) => setTargetPrice(e.target.value)}
              />
              <select
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                value={notificationMethod}
                onChange={(e) => setNotificationMethod(e.target.value)}
              >
                <option value="email">Email</option>
                <option value="sms">SMS</option>
              </select>
              <button
                onClick={handleCreateAlert}
                className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Create Alert
              </button>
            </div>
          </div>

          {/* Display Existing Alerts */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Your Alerts</h2>
            <ul className="space-y-4">
              {alerts.map(alert => (
                <li
                  key={alert._id}
                  className="flex justify-between items-center border-b pb-2"
                >
                  <div>
                    <p className="text-lg font-semibold">{alert.productName}</p>
                    <p className="text-sm text-gray-600">
                      Target Price: ${alert.targetPrice} | Last Checked Price: ${alert.lastCheckedPrice || 'N/A'}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    {alert.isTriggered && (
                      <span className="bg-green-100 text-green-700 px-3 py-1 rounded-lg text-sm">
                        Triggered
                      </span>
                    )}
                    <button
                      onClick={() => handleDeleteAlert(alert._id)}
                      className="text-red-500 hover:text-red-700 transition"
                      aria-label="Delete alert"
                    >
                      🗑️
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default PriceAlertPage;
