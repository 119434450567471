// File: src/pages/NotificationsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const NotificationsPage = ({ authToken }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/notifications/${authToken}`
        );
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
    fetchNotifications();
  }, [authToken]);

  const markAsRead = async (id) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/notifications/${id}/read`);
      setNotifications(notifications.map((n) =>
        n._id === id ? { ...n, isRead: true } : n
      ));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-2xl font-bold">Notifications</h1>
      <ul className="space-y-4">
        {notifications.map((notification) => (
          <li
            key={notification._id}
            className={`p-4 border rounded-lg flex justify-between items-center ${
              notification.isRead ? 'bg-gray-100' : 'bg-white'
            } shadow-sm hover:shadow-md transition-shadow`}
          >
            <div>
              <p className="text-gray-800">{notification.message}</p>
              <p className="text-sm text-gray-500">
                {new Date(notification.createdAt).toLocaleString()}
              </p>
            </div>
            {!notification.isRead && (
              <button
                onClick={() => markAsRead(notification._id)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              >
                Mark as Read
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationsPage;
