import React, { useState } from 'react';
import axios from 'axios';
import DealCard from '../components/DealCard';

const SearchPage = () => {
  const [category, setCategory] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [location, setLocation] = useState('');
  const [rating, setRating] = useState('');
  const [distance, setDistance] = useState(10); // Default distance filter in km
  const [sort, setSort] = useState('');
  const [source, setSource] = useState('');
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setError('');
    setLoading(true);

    // Input validation: Ensure min price is not higher than max price
    if (minPrice && maxPrice && parseFloat(minPrice) > parseFloat(maxPrice)) {
      setError('Min price cannot be greater than max price.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/deals/search`, {
        params: {
          category,
          minPrice,
          maxPrice,
          location,
          rating,
          distance,
          sort,
          source,
        },
      });
      setDeals(response.data);
    } catch (err) {
      console.error('Error fetching deals:', err);
      setError('Error fetching deals. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-5xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Search for Deals</h1>

      {/* Search Filters */}
      <div className="bg-white shadow rounded-lg p-6 mb-6 space-y-4">
        <input
          type="text"
          placeholder="Category"
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="number"
            placeholder="Min Price"
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Max Price"
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </div>
        <input
          type="text"
          placeholder="Location"
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <select
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          >
            <option value="">Any Rating</option>
            <option value="1">1 Star & Up</option>
            <option value="2">2 Stars & Up</option>
            <option value="3">3 Stars & Up</option>
            <option value="4">4 Stars & Up</option>
            <option value="5">5 Stars</option>
          </select>
          <select
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="">Sort By</option>
            <option value="priceAsc">Price: Low to High</option>
            <option value="priceDesc">Price: High to Low</option>
            <option value="ratingDesc">Rating: High to Low</option>
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">Distance (km)</label>
            <input
              type="range"
              min="0"
              max="50"
              step="5"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              className="w-full"
            />
            <p className="text-sm text-gray-500">Selected: {distance} km</p>
          </div>
          <select
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          >
            <option value="">Local MongoDB</option>
            <option value="affiliate">Affiliate (eBay)</option>
          </select>
        </div>
        <button
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {/* Error Message */}
      {error && <p className="text-red-500 mb-6">{error}</p>}

      {/* Results Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {loading ? (
          <div className="col-span-full flex justify-center">
            <div className="loader border-t-4 border-blue-500"></div>
          </div>
        ) : deals.length > 0 ? (
          deals.map((deal, index) => <DealCard deal={deal} key={index} />)
        ) : (
          <p className="col-span-full text-center text-gray-500">No deals found. Try adjusting your search criteria.</p>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
