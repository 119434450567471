// File: src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-4">
        {/* Footer Top Section */}
        <div className="flex flex-wrap justify-between items-center border-b border-gray-600 pb-4 mb-4">
          {/* Logo and Description */}
          <div className="mb-4 md:mb-0">
            <h2 className="text-yellow-400 text-xl font-bold mb-2">Meshabay</h2>
            <p className="text-gray-400 text-sm">
              Discover the best deals and offers tailored just for you!
            </p>
          </div>

          {/* Quick Links */}
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
            <Link to="/" className="hover:text-yellow-400">
              Home
            </Link>
            <Link to="/about" className="hover:text-yellow-400">
              About Us
            </Link>
            <Link to="/contact" className="hover:text-yellow-400">
              Contact
            </Link>
            <Link to="/deals" className="hover:text-yellow-400">
              Deals
            </Link>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="flex flex-wrap justify-between items-center">
          {/* Social Media Links */}
          <div className="flex space-x-4">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-yellow-400"
            >
              Facebook
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-yellow-400"
            >
              Twitter
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-yellow-400"
            >
              Instagram
            </a>
          </div>

          {/* Copyright Text */}
          <p className="text-gray-400 text-sm mt-4 md:mt-0">
            &copy; {new Date().getFullYear()} Meshabay. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
