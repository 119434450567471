// File: src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isDealsMenuOpen, setIsDealsMenuOpen] = useState(false);
  const [isVendorMenuOpen, setIsVendorMenuOpen] = useState(false);

  return (
    <nav className="bg-gray-800 text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="text-xl font-bold text-yellow-400">
          Meshabay
        </Link>

        {/* Navigation Links */}
        <div className="hidden md:flex space-x-6">
          <Link to="/" className="hover:text-yellow-400">
            Home
          </Link>
          <Link to="/about" className="hover:text-yellow-400">
            About Us
          </Link>

          {/* Deals Dropdown */}
          <div className="relative">
            <button
              onClick={() => setIsDealsMenuOpen(!isDealsMenuOpen)}
              className="hover:text-yellow-400"
            >
              Deals
            </button>
            {isDealsMenuOpen && (
              <div className="absolute left-0 mt-2 bg-gray-700 rounded shadow-lg">
                <Link
                  to="/deals/category/electronics"
                  className="block px-4 py-2 hover:bg-gray-600"
                  onClick={() => setIsDealsMenuOpen(false)}
                >
                  Electronics
                </Link>
                <Link
                  to="/deals/category/fashion"
                  className="block px-4 py-2 hover:bg-gray-600"
                  onClick={() => setIsDealsMenuOpen(false)}
                >
                  Fashion
                </Link>
                <Link
                  to="/deals/category/home"
                  className="block px-4 py-2 hover:bg-gray-600"
                  onClick={() => setIsDealsMenuOpen(false)}
                >
                  Home & Living
                </Link>
              </div>
            )}
          </div>

          <Link to="/contact" className="hover:text-yellow-400">
            Contact
          </Link>

          {/* Vendor Dropdown */}
          <div className="relative">
            <button
              onClick={() => setIsVendorMenuOpen(!isVendorMenuOpen)}
              className="hover:text-yellow-400"
            >
              Vendor
            </button>
            {isVendorMenuOpen && (
              <div className="absolute left-0 mt-2 bg-gray-700 rounded shadow-lg">
                <Link
                  to="/vendor/register"
                  className="block px-4 py-2 hover:bg-gray-600"
                  onClick={() => setIsVendorMenuOpen(false)}
                >
                  Register
                </Link>
                <Link
                  to="/vendor/login"
                  className="block px-4 py-2 hover:bg-gray-600"
                  onClick={() => setIsVendorMenuOpen(false)}
                >
                  Login
                </Link>
                <Link
                  to="/vendor/dashboard"
                  className="block px-4 py-2 hover:bg-gray-600"
                  onClick={() => setIsVendorMenuOpen(false)}
                >
                  Dashboard
                </Link>
              </div>
            )}
          </div>

          <Link to="/login" className="hover:text-yellow-400">
            Login
          </Link>
          <Link to="/forgot-password" className="hover:text-yellow-400">
            Forgot Password
          </Link>
          <Link to="/register" className="hover:text-yellow-400">
            Register
          </Link>
        </div>

        {/* Mobile Menu (Hamburger Icon) */}
        <div className="md:hidden">
          <button
            className="p-2 rounded hover:bg-gray-700"
            onClick={() => {
              setIsDealsMenuOpen(false);
              setIsVendorMenuOpen(false);
            }}
          >
            ☰
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
