import React from 'react';
import { Card, CardContent, Typography, Button, CardMedia, Box, Badge } from '@mui/material';
import { styled } from '@mui/system';

// Styled Card to include hover effects
const HoverCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: theme.spacing(1),
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
  '&:hover .hover-details': {
    opacity: 1,
    visibility: 'visible',
    transition: 'opacity 0.3s',
  },
}));

// Overlay box for hover details
const HoverDetails = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0,
  visibility: 'hidden',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const DealCard = ({ deal }) => {
  // Destructure properties from the deal object for better readability
  const {
    productName,
    description,
    price,
    rating,
    affiliateLink,
    location,
    category,
    image,
    isFeatured,
    views,
    expirationDate,
  } = deal;

  return (
    <HoverCard>
      {/* Badge for featured/popular deals */}
      {isFeatured && (
        <Badge
          color="primary"
          badgeContent="Featured"
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 2,
          }}
        />
      )}

      {/* Display product image if available */}
      {image ? (
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={`${productName} image`}
        />
      ) : (
        <Box
          sx={{
            height: 140,
            backgroundColor: 'grey.200',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary">
            No image available
          </Typography>
        </Box>
      )}

      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {productName}
        </Typography>

        {/* Ensure description is displayed, or fallback text if missing */}
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description || 'Description not available'}
        </Typography>

        {/* Display price, formatted to 2 decimal places */}
        <Typography variant="body1" color="primary" gutterBottom>
          {price ? `$${parseFloat(price).toFixed(2)}` : 'Price not available'}
        </Typography>

        {/* Display rating if available */}
        <Typography variant="body2" color="textSecondary">
          Rating: {rating || 'No rating available'}
        </Typography>

        {/* Display location if available */}
        {location && (
          <Typography variant="body2" color="textSecondary">
            Location: {location}
          </Typography>
        )}

        {/* Display category if available */}
        {category && (
          <Typography variant="body2" color="textSecondary">
            Category: {category}
          </Typography>
        )}

        {/* Conditional rendering for affiliate link */}
        {affiliateLink ? (
          <Button
            href={affiliateLink}
            target="_blank"
            variant="contained"
            sx={{ marginTop: 2 }}
            aria-label={`View deal for ${productName}`}
          >
            View Deal
          </Button>
        ) : (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Affiliate link not available
          </Typography>
        )}
      </CardContent>

      {/* Hover effect details */}
      <HoverDetails className="hover-details">
        <Typography variant="body1" gutterBottom>
          {expirationDate
            ? `Expires on: ${new Date(expirationDate).toLocaleDateString()}`
            : 'No expiration date'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {views ? `Views: ${views}` : 'Views data unavailable'}
        </Typography>
      </HoverDetails>
    </HoverCard>
  );
};

export default DealCard;
