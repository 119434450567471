// File: src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const LoginPage = ({ setAuthToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        email,
        password,
      });

      const { token } = response.data;
      localStorage.setItem('authToken', token); // Save token in local storage
      setAuthToken(token); // Update the auth token in the parent component
      setMessage('Login successful!');

      // Clear fields
      setEmail('');
      setPassword('');

      // Redirect to profile page after a short delay
      setTimeout(() => {
        navigate('/profile');
      }, 1000);
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  const handleThirdPartyLogin = (provider) => {
    // Placeholder function for third-party login
    console.log(`Logging in with ${provider}`);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800">Welcome Back</h2>
        <p className="text-center text-gray-600 mb-6">Login to access your account</p>

        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-500 text-white font-medium rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Login
          </button>
        </form>

        {error && (
          <div className="mt-4 p-3 text-red-700 bg-red-100 border border-red-400 rounded">
            {error}
          </div>
        )}
        {message && (
          <div className="mt-4 p-3 text-green-700 bg-green-100 border border-green-400 rounded">
            {message}
          </div>
        )}

        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">OR</span>
          </div>
        </div>

        <div className="flex justify-center gap-4 mt-4">
          <button
            onClick={() => handleThirdPartyLogin('Google')}
            className="flex items-center px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100"
          >
            <svg
              className="w-5 h-5 mr-2 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
            >
              <path
                fill="#fbc02d"
                d="M43.6 20.8H42V20H24v8h11.3c-1.9 5.5-7.2 8-11.3 8-6.6 0-12-5.4-12-12s5.4-12 12-12c3.1 0 5.9 1.2 8.1 3.1l5.9-5.9C34.5 6.2 29.6 4 24 4 12.9 4 4 12.9 4 24s8.9 20 20 20c10.3 0 18.9-7.5 20-17h-20v-6h19.6z"
              />
            </svg>
            Login with Google
          </button>
          <button
            onClick={() => handleThirdPartyLogin('Facebook')}
            className="flex items-center px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100"
          >
            <svg
              className="w-5 h-5 mr-2 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M22 12c0-5.53-4.47-10-10-10S2 6.47 2 12c0 5 3.66 9.13 8.44 9.88v-6.98H7.9V12h2.54v-1.9c0-2.5 1.5-3.88 3.8-3.88 1.1 0 2.26.2 2.26.2v2.5h-1.27c-1.25 0-1.64.78-1.64 1.58V12h2.78l-.44 2.9h-2.34v6.98C18.34 21.13 22 17 22 12z"
              />
            </svg>
            Login with Facebook
          </button>
        </div>

        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Don’t have an account?{' '}
            <Link to="/register" className="text-blue-500 hover:underline">
              Sign Up
            </Link>
          </p>
          <p className="mt-2 text-sm text-gray-600">
            <Link to="/forgot-password" className="text-blue-500 hover:underline">
              Forgot Password?
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
