import React from 'react';
import { FaStar, FaUser, FaHandsHelping } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <div
        className="flex flex-col justify-center items-center text-center h-screen bg-cover bg-center text-white px-4"
        style={{ backgroundImage: 'url("/assets/hero-background.jpg")' }}
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Discover the Best Deals Today
        </h1>
        <p className="text-lg md:text-xl mb-6">
          Save more, shop smart, and explore the best deals all in one place.
        </p>
        <Link
          to="/deals"
          className="px-6 py-3 text-lg font-semibold bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Explore Deals
        </Link>
      </div>

      {/* Features Section */}
      <div className="py-16 bg-gray-50 text-center">
        <h2 className="text-3xl font-bold mb-8">Why Choose Meshabay?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white shadow rounded-lg p-6 text-center">
            <FaStar size={40} className="text-yellow-400 mx-auto mb-4" />
            <h3 className="text-lg font-bold mb-2">Best Deals</h3>
            <p className="text-gray-600">
              Access top-notch deals and discounts curated just for you.
            </p>
          </div>
          <div className="bg-white shadow rounded-lg p-6 text-center">
            <FaUser size={40} className="text-green-500 mx-auto mb-4" />
            <h3 className="text-lg font-bold mb-2">Trusted Vendors</h3>
            <p className="text-gray-600">
              Shop with confidence from verified and reliable vendors.
            </p>
          </div>
          <div className="bg-white shadow rounded-lg p-6 text-center">
            <FaHandsHelping size={40} className="text-blue-500 mx-auto mb-4" />
            <h3 className="text-lg font-bold mb-2">Supportive Community</h3>
            <p className="text-gray-600">
              Join a community that values savings and smart shopping.
            </p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="py-16 bg-white text-center">
        <h2 className="text-3xl font-bold mb-8">What Our Users Say</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gray-50 shadow rounded-lg p-6">
            <p className="text-gray-600">
              "Meshabay has transformed the way I shop. I always find the best deals!"
            </p>
            <p className="text-gray-500 italic mt-4">- Sarah K.</p>
          </div>
          <div className="bg-gray-50 shadow rounded-lg p-6">
            <p className="text-gray-600">
              "The platform is user-friendly, and the vendors are trustworthy. Highly recommend!"
            </p>
            <p className="text-gray-500 italic mt-4">- James L.</p>
          </div>
          <div className="bg-gray-50 shadow rounded-lg p-6">
            <p className="text-gray-600">
              "Great deals, amazing savings, and a supportive community. Meshabay rocks!"
            </p>
            <p className="text-gray-500 italic mt-4">- Monica P.</p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="py-8 bg-gray-800 text-white text-center">
        <p className="mb-4">© {new Date().getFullYear()} Meshabay. All rights reserved.</p>
        <div className="flex justify-center space-x-4 mb-4">
          <Link to="/terms" className="text-blue-400 hover:underline">
            Terms of Service
          </Link>
          <span>|</span>
          <Link to="/privacy" className="text-blue-400 hover:underline">
            Privacy Policy
          </Link>
        </div>
        <div className="flex justify-center space-x-4">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500 transition"
          >
            <img src="/assets/facebook-icon.png" alt="Facebook" className="w-6 h-6" />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-400 transition"
          >
            <img src="/assets/twitter-icon.png" alt="Twitter" className="w-6 h-6" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-pink-500 transition"
          >
            <img src="/assets/instagram-icon.png" alt="Instagram" className="w-6 h-6" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
