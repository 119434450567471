import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VendorDashboard = ({ vendorAuthToken }) => {
  const [deals, setDeals] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [expirationDate, setExpirationDate] = useState(null);
  const [status, setStatus] = useState(true);
  const [inventory, setInventory] = useState(0);
  const [file, setFile] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const steps = ['Upload File', 'Verify Data', 'Confirm Upload'];

  useEffect(() => {
    const fetchDealsAndAnalytics = async () => {
      setLoading(true);
      try {
        const dealsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/vendors/deals`, {
          headers: { Authorization: `Bearer ${vendorAuthToken}` },
        });
        const analyticsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/vendors/analytics`, {
          headers: { Authorization: `Bearer ${vendorAuthToken}` },
        });
        const feedbackResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/vendors/feedback`, {
          headers: { Authorization: `Bearer ${vendorAuthToken}` },
        });

        setDeals(dealsResponse.data);
        setAnalytics(analyticsResponse.data);
        setFeedback(feedbackResponse.data);
      } catch (error) {
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchDealsAndAnalytics();
  }, [vendorAuthToken]);

  const handleAddDeal = async () => {
    setError('');
    setSuccess('');
    if (!productName || !price || inventory < 0) {
      setError('Product name, price, and non-negative inventory are required.');
      return;
    }

    const newDeal = {
      productName,
      price,
      description,
      expirationDate,
      status: status ? 'Active' : 'Inactive',
      inventory,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/vendors/deals`, newDeal, {
        headers: { Authorization: `Bearer ${vendorAuthToken}` },
      });
      setDeals([...deals, response.data]);
      setProductName('');
      setPrice('');
      setDescription('');
      setExpirationDate(null);
      setStatus(true);
      setInventory(0);
      setSuccess('Deal added successfully!');
    } catch (error) {
      setError('Failed to add deal. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/deals/${id}`, {
        headers: { Authorization: `Bearer ${vendorAuthToken}` },
      });
      setDeals(deals.filter((deal) => deal._id !== id));
      setSuccess('Deal deleted successfully!');
    } catch (error) {
      setError('Failed to delete deal. Please try again.');
    }
  };

  const handleEdit = (deal) => {
    setSelectedDeal(deal);
    setProductName(deal.productName);
    setPrice(deal.price);
    setDescription(deal.description);
    setExpirationDate(deal.expirationDate);
    setStatus(deal.status === 'Active');
    setInventory(deal.inventory);
    setIsEditDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    if (!selectedDeal) return;

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/deals/${selectedDeal._id}`, {
        productName,
        price,
        description,
        expirationDate,
        status: status ? 'Active' : 'Inactive',
        inventory,
      }, {
        headers: { Authorization: `Bearer ${vendorAuthToken}` },
      });

      setDeals(deals.map((deal) => (deal._id === selectedDeal._id ? response.data : deal)));
      setSuccess('Deal updated successfully!');
      setIsEditDialogOpen(false);
      setProductName('');
      setPrice('');
      setDescription('');
      setExpirationDate(null);
      setStatus(true);
      setInventory(0);
      setSelectedDeal(null);
    } catch (error) {
      setError('Failed to update deal. Please try again.');
    }
  };

  const renderDealAnalytics = (dealId) => {
    const dealAnalytics = analytics[dealId] || { views: 0, clicks: 0, conversions: 0 };
    return (
      <div className="text-sm text-gray-600 space-y-1">
        <p>Views: {dealAnalytics.views}</p>
        <p>Clicks: {dealAnalytics.clicks}</p>
        <p>Conversions: {dealAnalytics.conversions}</p>
      </div>
    );
  };

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-3xl font-bold">Vendor Dashboard</h1>

      <div className="space-y-4">
        <h2 className="text-2xl font-semibold">Your Deals</h2>
        <ul className="space-y-4">
          {deals.map((deal) => (
            <li key={deal._id} className="border-b pb-4">
              <div>
                <h3 className="text-lg font-semibold">{deal.productName}</h3>
                <p className="text-gray-500">{deal.description}</p>
                <p className="text-green-600">${deal.price.toFixed(2)}</p>
                {renderDealAnalytics(deal._id)}
                <div className="mt-2 flex space-x-2">
                  <button
                    className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                    onClick={() => handleEdit(deal)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-600"
                    onClick={() => handleDelete(deal._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VendorDashboard;
